<template>
  <div>
    <AppModal
      dialogTitle="Change Password"
      :disableClose="true">
      <b-form @submit.prevent="changePassword">
        <b-input-group
          prepend="Current Password"
          v-if="user.is_default_password === false">
          <b-form-input
            id="currentPassword"
            type="password"
            placeholder="Current Password"
            aria-describedby="CurrentPassword"
            @input="$v.currentPass.$touch()"
            v-model="currentPass"
            :state="!$v.currentPass.$error"
            autocomplete="off"
          ></b-form-input>
          <b-form-invalid-feedback id="currentPassword">
            <span v-if="!$v.currentPass.minLength">
              Current Password must have at least
              {{$v.currentPass.$params.minLength.min}} characters
            </span>
            <span v-if="!$v.currentPass.currentPassMatch &&
              $v.currentPass.minLength">
              Incorrect Current Password
            </span>
          </b-form-invalid-feedback>
        </b-input-group>

        <b-input-group prepend="New Password">
          <b-form-input
            id="password"
            type="password"
            placeholder="New Password"
            aria-describedby="Password"
            @input="$v.pass.$touch()"
            v-model="pass"
            :state="!$v.pass.$error"
            autocomplete="off"
          ></b-form-input>
          <b-form-invalid-feedback id="Password">
            <span v-if="!$v.pass.minLength">
              Password must have at least
              {{$v.pass.$params.minLength.min}} characters
            </span>
            <span v-if="!$v.pass.validNewPassword">
              Current and New Password must not be the same.
            </span>
          </b-form-invalid-feedback>
        </b-input-group>

        <b-input-group prepend="Confirm Password">
          <b-form-input
            id="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            aria-describedby="ConfirmPassword"
            @input="$v.confirmPass.$touch()"
            v-model="confirmPass"
            :state="!$v.confirmPass.$error"
            autocomplete="off"
          ></b-form-input>
          <b-form-invalid-feedback id="ConfirmPassword">
            <span v-if="!$v.confirmPass.minLength">
              Confirm Password must have at least
              {{$v.confirmPass.$params.minLength.min}} characters
            </span>
            <span v-if="!$v.confirmPass.sameAsPassword &&
              $v.confirmPass.minLength">
                Passwords must be the same
              </span>
          </b-form-invalid-feedback>
        </b-input-group>

        <!-- Update Button -->
        <div class="text-center">
          <b-button
            class="btn btn-success btn-h3-small"
            type="submit"
            :disabled="$v.$invalid">
            Update
          </b-button>
          &nbsp;
          <b-button
            class="btn btn-danger btn-h3-small"
            type="button"
            @click="$emit('close')">
            Cancel
          </b-button>
        </div>
      </b-form>
    </AppModal>
    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { required, minLength, sameAs } from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        loading          : false,
        currentPass      : null,
        pass             : null,
        confirmPass      : null,
        currentPassMatch : true,
      }
    },
    computed : {
      ...mapGetters({
        user : 'user/user',
      }),
    },
    components : {
      'AppLoader' : () => import('../../components/layout/AppLoader'),
      'AppModal'  : () => import('../../components/layout/AppModal'),
    },
    watch : {
      currentPass() {
        this.currentPassMatch = true;
        this.checkCurrentPassword();
      },
    },
    methods : {

      /**
       * Update Profile's password in the database
       */
      changePassword() {
        this.loading = true;
        this.$http.put('api/user', {
          updateFields : {
            password : this.pass,
          },
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Changed Password Successfully!',
          });
          this.$store.dispatch('user/getUpdatedUser');
          this.$emit('close');
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        }).finally(() => {
          this.loading = false;
        });
      },

      // Verify Current Password
      // eslint-disable-next-line no-undef
      checkCurrentPassword : _.debounce(function() {
        this.$http.get('api/user/pass/check', {
          params : {
            password : this.currentPass,
          },
        }).then(() => {
          this.currentPassMatch = true;
        }).catch(() => {
          this.currentPassMatch = false;
        });
      }, 1500),
    },
    validations() {
      if (this.user.is_default_password === true) {
        return {
          pass : {
            required,
            minLength : minLength(5),
          },
          confirmPass : {
            required,
            minLength      : minLength(5),
            sameAsPassword : sameAs('pass'),
          },
        }
      }

      return {
        currentPass : {
          required,
          minLength : minLength(5),
          currentPassMatch() {
            return this.currentPassMatch;
          },
        },
        pass : {
          required,
          minLength : minLength(5),
          validNewPassword() {
            return (this.currentPass === this.pass) ? false : true;
          },
        },
        confirmPass : {
          required,
          minLength      : minLength(5),
          sameAsPassword : sameAs('pass'),
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .input-group {
    margin-bottom: 1rem;
  }

  .input-group-text {
    background-color: $primary-color !important;
    color: #fff;
    font-family: "WickedMouse";
    font-size: 0.8em;
    -webkit-text-stroke: 1px $blue-text-stroke;
  }

  /* Extra Small devices */
@media only screen and (max-width: $xs-max) {
  .input-group-text {
    width: 10rem;
    font-size: 0.75em;
  }
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (min-width: $sm-min) {
  .input-group-text {
    width: 15rem;
  }
}

/* Medium devices (landscape tablets) */
@media only screen and (min-width: $md-min) {}

/* Large devices (laptops/desktops) */
@media only screen and (min-width: $lg-min) {}

/* Extra large devices (large laptops and desktops) */
@media only screen and (min-width: $xl-min) {}
@media only screen and (min-width: $special) {}
@media only screen and (min-width: $xl2-min) {}

/* iMac Res */
@media only screen and (min-width: $xl5-min) {}


</style>